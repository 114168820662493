/* eslint-disable
    handle-callback-err,
    no-return-assign,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
'use strict';

/* COMMON CODE */

/**
 * Provides a set of common callback methods to the $scope parameter. These methods are used
 * in the various machines' admin controllers.
 *
 * Provides :
 *  - $scope.submited(content)
 *  - $scope.cancel()
 *  - $scope.fileinputClass(v)
 *  - $scope.addFile()
 *  - $scope.deleteFile(file)
 *
 * Requires :
 *  - $scope.machine.machine_files_attributes = []
 *  - $state (Ui-Router) [ 'app.public.machines_list' ]
 */
class MachinesController {
  constructor ($scope, $state) {
    /**
     * For use with ngUpload (https://github.com/twilson63/ngUpload).
     * Intended to be the callback when the upload is done: any raised error will be stacked in the
     * $scope.alerts array. If everything goes fine, the user is redirected to the machines list.
     * @param content {Object} JSON - The upload's result
     */
    $scope.submited = function (content) {
      if ((content.id == null)) {
        $scope.alerts = [];
        angular.forEach(content, function (v, k) {
          angular.forEach(v, function (err) {
            $scope.alerts.push({
              msg: k + ': ' + err,
              type: 'danger'
            });
          });
        });
      } else {
        return $state.go('app.public.machines_list');
      }
    };

    /**
     * Changes the current user's view, redirecting him to the machines list
     */
    $scope.cancel = function () { $state.go('app.public.machines_list'); };

    /**
     * For use with 'ng-class', returns the CSS class name for the uploads previews.
     * The preview may show a placeholder or the content of the file depending on the upload state.
     * @param v {*} any attribute, will be tested for truthiness (see JS evaluation rules)
     */
    $scope.fileinputClass = function (v) {
      if (v) {
        return 'fileinput-exists';
      } else {
        return 'fileinput-new';
      }
    };

    /**
     * This will create a single new empty entry into the machine attachements list.
     */
    $scope.addFile = function () { $scope.machine.machine_files_attributes.push({}); };

    /**
     * This will remove the given file from the machine attachements list. If the file was previously uploaded
     * to the server, it will be marked for deletion on the server. Otherwise, it will be simply truncated from
     * the attachements array.
     * @param file {Object} the file to delete
     */
    $scope.deleteFile = function (file) {
      const index = $scope.machine.machine_files_attributes.indexOf(file);
      if (file.id != null) {
        return file._destroy = true;
      } else {
        return $scope.machine.machine_files_attributes.splice(index, 1);
      }
    };
  }
}

/**
 * Controller used in the public listing page, allowing everyone to see the list of machines
 */
Application.Controllers.controller('MachinesController', ['$scope', '$state', '_t', 'AuthService', 'Machine', '$uibModal', 'settingsPromise', 'Member', 'uiTourService', 'machinesPromise', 'growl', 'helpers',
  function ($scope, $state, _t, AuthService, Machine, $uibModal, settingsPromise, Member, uiTourService, machinesPromise, growl, helpers) {
    /* PUBLIC SCOPE */

    // the application global settings
    $scope.settings = settingsPromise;

    /**
     * Redirect the user to the machine details page
     */
    $scope.showMachine = function (machine) { $state.go('app.public.machines_show', { id: machine.slug }); };

    /**
     * Shows an error message forwarded from a child component
     */
    $scope.onError = function (message) {
      growl.error(message);
    }

    /**
     * Shows a success message forwarded from a child react components
     */
    $scope.onSuccess = function (message) {
      growl.success(message)
    }

    /**
     * Open the modal dialog to log the user and resolves the returned promise when the logging process
     * was successfully completed.
     */
    $scope.onLoginRequest = function (e) {
      return new Promise((resolve, _reject) => {
        $scope.login(e, resolve);
      });
    }

    /**
     * Redirect the user to the training reservation page
     */
    $scope.onEnrollRequest = function (trainingId) {
      $state.go('app.logged.trainings_reserve', { id: trainingId });
    }

    /**
     * Callback to book a reservation for the current machine
     */
    $scope.reserveMachine = function (machine) {
      $state.go('app.logged.machines_reserve', { id: machine.slug });
    }

    $scope.canProposePacks = function () {
      return AuthService.isAuthorized(['admin', 'manager']) || !helpers.isUserValidationRequired($scope.settings, 'pack') || (helpers.isUserValidationRequired($scope.settings, 'pack') && helpers.isUserValidated($scope.currentUser));
    };

    /**
     * Setup the feature-tour for the machines page. (admins only)
     * This is intended as a contextual help (when pressing F1)
     */
    $scope.setupMachinesTour = function () {
      // setup the tour for admins only
      if (AuthService.isAuthorized(['admin', 'manager'])) {
        // get the tour defined by the ui-tour directive
        const uitour = uiTourService.getTourByName('machines');
        if (AuthService.isAuthorized('admin')) {
          uitour.createStep({
            selector: 'body',
            stepId: 'welcome',
            order: 0,
            title: _t('app.public.tour.machines.welcome.title'),
            content: _t('app.public.tour.machines.welcome.content'),
            placement: 'bottom',
            orphan: true
          });
          if (machinesPromise.length > 0) {
            uitour.createStep({
              selector: '.machines-list .show-button',
              stepId: 'view',
              order: 1,
              title: _t('app.public.tour.machines.view.title'),
              content: _t('app.public.tour.machines.view.content'),
              placement: 'top'
            });
          }
        } else {
          uitour.createStep({
            selector: 'body',
            stepId: 'welcome_manager',
            order: 0,
            title: _t('app.public.tour.machines.welcome_manager.title'),
            content: _t('app.public.tour.machines.welcome_manager.content'),
            placement: 'bottom',
            orphan: true
          });
        }
        if (machinesPromise.length > 0) {
          uitour.createStep({
            selector: '.machines-list .reserve-button',
            stepId: 'reserve',
            order: 2,
            title: _t('app.public.tour.machines.reserve.title'),
            content: _t('app.public.tour.machines.reserve.content'),
            placement: 'top'
          });
        }
        uitour.createStep({
          selector: 'body',
          stepId: 'conclusion',
          order: 3,
          title: _t('app.public.tour.conclusion.title'),
          content: _t('app.public.tour.conclusion.content'),
          placement: 'bottom',
          orphan: true
        });
        // on tour end, save the status in database
        uitour.on('ended', function () {
          if (uitour.getStatus() === uitour.Status.ON && $scope.currentUser.profile_attributes.tours.indexOf('machines') < 0) {
            Member.completeTour({ id: $scope.currentUser.id }, { tour: 'machines' }, function (res) {
              $scope.currentUser.profile_attributes.tours = res.tours;
            });
          }
        });
        // if the user has never seen the tour, show him now
        if (settingsPromise.feature_tour_display !== 'manual' && $scope.currentUser.profile_attributes.tours.indexOf('machines') < 0) {
          uitour.start();
        }
      }
    }
  }
]);

/**
 * Controller used in the machine creation page (admin)
 */
Application.Controllers.controller('NewMachineController', ['$scope', '$state', 'CSRF', function ($scope, $state, CSRF) {
  CSRF.setMetaTags();

  // API URL where the form will be posted
  $scope.actionUrl = '/api/machines/';

  // Form action on the above URL
  $scope.method = 'post';

  // default machine parameters
  $scope.machine =
    { machine_files_attributes: [] };

  // Using the MachinesController
  return new MachinesController($scope, $state);
}
]);

/**
 * Controller used in the machine edition page (admin)
 */
Application.Controllers.controller('EditMachineController', ['$scope', '$state', '$transition$', 'machinePromise', 'CSRF',
  function ($scope, $state, $transition$, machinePromise, CSRF) {
  /* PUBLIC SCOPE */

    // API URL where the form will be posted
    $scope.actionUrl = `/api/machines/${$transition$.params().id}`;

    // Form action on the above URL
    $scope.method = 'put';

    // Retrieve the details for the machine id in the URL, if an error occurs redirect the user to the machines list
    $scope.machine = machinePromise;

    /* PRIVATE SCOPE */

    /**
   * Kind of constructor: these actions will be realized first when the controller is loaded
   */
    const initialize = function () {
      CSRF.setMetaTags();

      // Using the MachinesController
      return new MachinesController($scope, $state);
    };

    // !!! MUST BE CALLED AT THE END of the controller
    return initialize();
  }
]);

/**
 * Controller used in the machine details page (public)
 */
Application.Controllers.controller('ShowMachineController', ['$scope', '$state', '$uibModal', '_t', 'Machine', 'growl', 'machinePromise', 'dialogs',
  function ($scope, $state, $uibModal, _t, Machine, growl, machinePromise, dialogs) {
  // Retrieve the details for the machine id in the URL, if an error occurs redirect the user to the machines list
    $scope.machine = machinePromise;

    /**
     * Callback to delete the current machine (admins only)
     */
    $scope.delete = function (machine) {
    // check the permissions
      if ($scope.currentUser.role !== 'admin') {
        console.error(_t('app.public.machines_show.unauthorized_operation'));
      } else {
        dialogs.confirm({
          resolve: {
            object () {
              return {
                title: _t('app.public.machines_show.confirmation_required'),
                msg: _t('app.public.machines_show.do_you_really_want_to_delete_this_machine')
              };
            }
          }
        }
        , function () { // deletion confirmed
        // delete the machine then redirect to the machines listing
          machine.$delete(
            function () { $state.go('app.public.machines_list'); },
            function (error) { growl.warning(_t('app.public.machines_show.the_machine_cant_be_deleted_because_it_is_already_reserved_by_some_users')); console.error(error); }
          );
        });
      }
    };

    /**
     * Shows an error message forwarded from a child component
     */
    $scope.onError = function (message) {
      growl.error(message);
    }

    /**
     * Shows a success message forwarded from a child react components
     */
    $scope.onSuccess = function (message) {
      growl.success(message)
    }


    /**
     * Open the modal dialog to log the user and resolves the returned promise when the logging process
     * was successfully completed.
     */
    $scope.onLoginRequest = function (e) {
      return new Promise((resolve, _reject) => {
        $scope.login(e, resolve);
      });
    }

    /**
     * Redirect the user to the training reservation page
     */
    $scope.onEnrollRequest = function (trainingId) {
      $state.go('app.logged.trainings_reserve', { id: trainingId });
    }

    /**
     * Callback to book a reservation for the current machine
     */
    $scope.reserveMachine = function (machine) {
      $state.go('app.logged.machines_reserve', { id: machine.slug });
    }
  }
]);

/**
 * Controller used in the machine reservation page (for logged users who have completed the training and admins).
 * This controller workflow is pretty similar to the trainings reservation controller.
 */

Application.Controllers.controller('ReserveMachineController', ['$scope', '$transition$', '_t', 'moment', 'Auth', '$timeout', 'Member', 'Availability', 'plansPromise', 'groupsPromise', 'machinePromise', 'settingsPromise', 'uiCalendarConfig', 'CalendarConfig', 'Reservation', 'growl', 'helpers', 'AuthService',
  function ($scope, $transition$, _t, moment, Auth, $timeout, Member, Availability, plansPromise, groupsPromise, machinePromise, settingsPromise, uiCalendarConfig, CalendarConfig, Reservation, growl, helpers, AuthService) {
  /* PRIVATE STATIC CONSTANTS */

    // Slot free to be booked
    const FREE_SLOT_BORDER_COLOR = '#e4cd78';

    // Slot already booked by another user
    const UNAVAILABLE_SLOT_BORDER_COLOR = '#1d98ec';

    // Slot already booked by the current user
    const BOOKED_SLOT_BORDER_COLOR = '#b2e774';

    /* PUBLIC SCOPE */

    // bind the machine availabilities with full-Calendar events
    $scope.eventSources = [];

    // indicates the state of the current view : calendar or plans information
    $scope.plansAreShown = false;

    // will store the user's plan if he chose to buy one
    $scope.selectedPlan = null;

    // the moment when the plan selection changed for the last time, used to trigger changes in the cart
    $scope.planSelectionTime = null;

    // mapping of fullCalendar events.
    $scope.events = {
      reserved: [], // Slots that the user wants to book
      modifiable: null, // Slot that the user wants to change
      placable: null, // Destination slot for the change
      paid: [], // Slots that were just booked by the user (transaction ok)
      moved: null // Slots that were just moved by the user (change done) -> {newSlot:* oldSlot: *}
    };

    // the moment when the slot selection changed for the last time, used to trigger changes in the cart
    $scope.selectionTime = null;

    // the last clicked event in the calender
    $scope.selectedEvent = null;

    // the application global settings
    $scope.settings = settingsPromise;

    // all plans, used in <cart>
    $scope.plans = plansPromise;

    // all groups, used in <cart>
    $scope.groups = groupsPromise;

    // the user to deal with, ie. the current user for non-admins
    $scope.ctrl =
    { member: {} };

    // current machine to reserve
    $scope.machine = machinePromise;

    // will be set to a Promise and resolved after the payment is sone
    $scope.afterPaymentPromise = null;

    // fullCalendar (v2) configuration
    $scope.calendarConfig = CalendarConfig({
      minTime: moment.duration(moment(settingsPromise.booking_window_start).format('HH:mm:ss')),
      maxTime: moment.duration(moment(settingsPromise.booking_window_end).format('HH:mm:ss')),
      eventClick (event, jsEvent, view) {
        return calendarEventClickCb(event, jsEvent, view);
      },
      eventRender (event, element, view) {
        return eventRenderCb(event, element);
      }
    });

    // Global config: message to the end user concerning the subscriptions rules
    $scope.subscriptionExplicationsAlert = settingsPromise.subscription_explications_alert;

    // Global config: message to the end user concerning the machine bookings
    $scope.machineExplicationsAlert = settingsPromise.machine_explications_alert;

    // Global config: is the user validation required ?
    $scope.enableUserValidationRequired = settingsPromise.user_validation_required === 'true';

    /**
     * Change the last selected slot's appearance to looks like 'added to cart'
     */
    $scope.markSlotAsAdded = function () {
      $scope.selectedEvent.backgroundColor = FREE_SLOT_BORDER_COLOR;
      $scope.selectedEvent.title = _t('app.logged.machines_reserve.i_reserve');
      updateEvents($scope.selectedEvent);
    };

    /**
     * Change the last selected slot's appearance to looks like 'never added to cart'
     */
    $scope.markSlotAsRemoved = function (slot) {
      slot.backgroundColor = 'white';
      slot.borderColor = FREE_SLOT_BORDER_COLOR;
      slot.title = '';
      slot.isValid = false;
      slot.slot_id = null;
      slot.is_reserved = false;
      slot.can_modify = false;
      slot.offered = false;
      updateEvents(slot);
    };

    /**
     * Callback when a slot was successfully cancelled. Reset the slot style as 'ready to book'
     */
    $scope.slotCancelled = function () { $scope.markSlotAsRemoved($scope.selectedEvent); };

    /**
     * Change the last selected slot's appearance to looks like 'currently looking for a new destination to exchange'
     */
    $scope.markSlotAsModifying = function () {
      $scope.selectedEvent.backgroundColor = '#eee';
      $scope.selectedEvent.title = _t('app.logged.machines_reserve.i_change');
      updateEvents($scope.selectedEvent);
    };

    /**
     * Change the last selected slot's appearance to looks like 'the slot being exchanged will take this place'
     */
    $scope.changeModifyMachineSlot = function () {
      if ($scope.events.placable) {
        $scope.events.placable.backgroundColor = 'white';
        $scope.events.placable.title = '';
        updateEvents($scope.events.placable);
      }
      if (!$scope.events.placable || ($scope.events.placable._id !== $scope.selectedEvent._id)) {
        $scope.selectedEvent.backgroundColor = '#bbb';
        $scope.selectedEvent.title = _t('app.logged.machines_reserve.i_shift');
        updateEvents($scope.selectedEvent);
      }
      return true;
    };

    /**
     * When modifying an already booked reservation, callback when the modification was successfully done.
     */
    $scope.modifyMachineSlot = function () {
      const save = {
        slotId: $scope.events.modifiable.slot_id,
        borderColor: $scope.events.modifiable.borderColor,
        user: angular.copy($scope.events.modifiable.user),
        title: (!$scope.events.modifiable.user || $scope.currentUser.id === $scope.events.modifiable.user.id) ? _t('app.logged.machines_reserve.i_ve_reserved') : _t('app.logged.machines_reserve.not_available')
      };

      $scope.events.modifiable.backgroundColor = 'white';
      $scope.events.modifiable.title = '';
      $scope.events.modifiable.borderColor = FREE_SLOT_BORDER_COLOR;
      $scope.events.modifiable.slot_id = null;
      $scope.events.modifiable.is_reserved = false;
      $scope.events.modifiable.can_modify = false;
      updateEvents($scope.events.modifiable);

      $scope.events.placable.title = save.title;
      $scope.events.placable.backgroundColor = 'white';
      $scope.events.placable.borderColor = save.borderColor;
      $scope.events.placable.slot_id = save.slotId;
      $scope.events.placable.is_reserved = true;
      $scope.events.placable.can_modify = true;
      $scope.events.placable.user = angular.copy(save.user);
      updateEvents($scope.events.placable);

      refetchCalendar();
    };

    /**
     * Cancel the current booking modification, resetting the whole process
     */
    $scope.cancelModifyMachineSlot = function () {
      if ($scope.events.placable) {
        $scope.events.placable.backgroundColor = 'white';
        $scope.events.placable.title = '';
      }
      $scope.events.modifiable.title = $scope.currentUser.id === $scope.events.modifiable.user.id ? _t('app.logged.machines_reserve.i_ve_reserved') : _t('app.logged.machines_reserve.not_available');
      $scope.events.modifiable.backgroundColor = 'white';

      updateEvents($scope.events.placable, $scope.events.modifiable);
    };

    /**
     * Callback to deal with the reservations of the user selected in the dropdown list instead of the current user's
     * reservations. (admins only)
     */
    $scope.updateMember = function () {
      $scope.plansAreShown = false;
      $scope.selectedPlan = null;
      Member.get({ id: $scope.ctrl.member.id }, function (member) { $scope.ctrl.member = member; });
    };

    /**
     * Changes the user current view from the plan subscription screen to the machine reservation agenda
     * @param e {Object} see https://docs.angularjs.org/guide/expression#-event-
     */
    $scope.doNotSubscribePlan = function (e) {
      e.preventDefault();
      $scope.plansAreShown = false;
      $scope.selectPlan($scope.selectedPlan);
      return $scope.planSelectionTime = new Date();
    };

    /**
     * Switch the user's view from the reservation agenda to the plan subscription
     */
    $scope.showPlans = function () { $scope.plansAreShown = true; };

    /**
     * Add the provided plan to the current shopping cart
     * @param plan {Object} the plan to subscribe
     */
    $scope.selectPlan = function (plan) {
      setTimeout(() => {
        // toggle selected plan
        if ($scope.selectedPlan !== plan) {
          $scope.selectedPlan = plan;
        } else {
          $scope.selectedPlan = null;
        }
        $scope.planSelectionTime = new Date();
        $scope.$apply();
      }, 50);
    };

    $scope.canSelectPlan = function () {
      return helpers.isUserValidatedByType($scope.ctrl.member, $scope.settings, 'subscription');
    };

    /**
     * Check if the provided plan is currently selected
     * @param plan {Object} Resource plan
     */
    $scope.isSelected = function (plan) {
      return $scope.selectedPlan === plan;
    };

    /**
     * Once the reservation is booked (payment process successfully completed), change the event style
     * in fullCalendar, update the user's subscription and free-credits if needed
     * @param paymentDocument {Invoice|PaymentSchedule}
     */
    $scope.afterPayment = function (paymentDocument) {
      Reservation.get({ id: paymentDocument.main_object.id }, function (reservation) {
        angular.forEach($scope.events.reserved, function (machineSlot, key) {
          machineSlot.is_reserved = true;
          machineSlot.can_modify = true;
          if ($scope.currentUser.role === 'admin' || ($scope.currentUser.role === 'manager' && reservation.user_id !== $scope.currentUser.id)) {
            // an admin or a manager booked for someone else
            machineSlot.title = _t('app.logged.machines_reserve.not_available');
            machineSlot.borderColor = UNAVAILABLE_SLOT_BORDER_COLOR;
            updateMachineSlot(machineSlot, reservation, $scope.ctrl.member);
          } else {
            // booked for "myself"
            machineSlot.title = _t('app.logged.machines_reserve.i_ve_reserved');
            machineSlot.borderColor = BOOKED_SLOT_BORDER_COLOR;
            updateMachineSlot(machineSlot, reservation, $scope.currentUser);
          }
          machineSlot.backgroundColor = 'white';
        });

        if ($scope.selectedPlan) {
          $scope.ctrl.member.subscribed_plan = angular.copy($scope.selectedPlan);
          if ($scope.ctrl.member.id === Auth._currentUser.id) {
            Auth._currentUser.subscribed_plan = angular.copy($scope.selectedPlan);
          }
          $scope.plansAreShown = false;
          $scope.selectedPlan = null;
        }

        refetchCalendar();

        // trigger the refresh of react components
        setTimeout(() => {
          $scope.afterPaymentPromise = new Promise(resolve => {
            resolve();
          });
          $scope.$apply();
        }, 50);
      });
    };

    /**
     * To use as callback in Array.prototype.filter to get only enabled plans
     */
    $scope.filterDisabledPlans = function (plan) { return !plan.disabled; };

    /**
     * Callback triggered after a successful prepaid-pack purchase
     */
    $scope.onSuccess = function (message) {

      growl.success(message);
    };

    /**
     * Callback triggered by react components
     */
    $scope.onError = function (message) {
      growl.error(message);
    };

    $scope.isShowPacks = function () {
      return !helpers.isUserValidationRequired($scope.settings, 'pack') || (helpers.isUserValidationRequired($scope.settings, 'pack') && helpers.isUserValidated($scope.ctrl.member));
    };

    /* PRIVATE SCOPE */

    /**
     * Kind of constructor: these actions will be realized first when the controller is loaded
     */
    const initialize = function () {
      $scope.eventSources.push({
        events: function (start, end, timezone, callback) {
          Availability.machine({ machineId: $transition$.params().id }, function (availabilities) {
            callback(availabilities);
          });
        },
        textColor: 'black'
      });

      if ($scope.currentUser.role !== 'admin') {
        return Member.get({ id: $scope.currentUser.id }, function (member) { $scope.ctrl.member = member; });
      }
    };

    /**
     * Triggered when the user click on a reservation slot in the agenda.
     * Defines the behavior to adopt depending on the slot status (already booked, free, ready to be reserved ...),
     * the user's subscription (current or about to be took) and the time (the user cannot modify a booked reservation
     * if it's too late).
     */
    const calendarEventClickCb = function (event, jsEvent, view) {
      if (!AuthService.isAuthorized(['admin', 'manager']) && (helpers.isUserValidationRequired($scope.settings, 'machine') && !helpers.isUserValidated($scope.ctrl.member))) {
        return;
      }
      $scope.selectedEvent = event;
      return $scope.selectionTime = new Date();
    };

    /**
     * Triggered when fullCalendar tries to graphically render an event block.
     * Append the event tag into the block, just after the event title.
     * @see http://fullcalendar.io/docs/event_rendering/eventRender/
     */
    const eventRenderCb = function (event, element) {
      if (($scope.currentUser.role === 'admin') && (event.tags.length > 0)) {
        let html = '';
        for (let tag of Array.from(event.tags)) {
          html += `<span class='label label-success text-white' title='${tag.name}'>${tag.name}</span>`;
        }
        element.find('.fc-time').append(html);
      }
    };

    /**
     * After payment, update the id of the newly reserved slot with the id returned by the server.
     * This will allow the user to modify the reservation he just booked. The associated user will also be registered
     * with the slot.
     * @param slot {Object}
     * @param reservation {Object}
     * @param user {Object} user associated with the slot
     */
    const updateMachineSlot = function (slot, reservation, user) {
      angular.forEach(reservation.slots, function (s) {
        if (slot.start.isSame(s.start_at)) {
          slot.slot_id = s.id;
          slot.user = user;
        }
      });
      updateEvents(slot);
    };

    /**
     * Update the calendar's display to render the new attributes of the events
     * @param events Object[] events to update in full-calendar
     */
    const updateEvents = function (...events) {
      const realEvents = events.filter(e => !_.isNil(e));
      uiCalendarConfig.calendars.calendar.fullCalendar('updateEvents', realEvents);
    };

    /**
     * Asynchronously fetch the events from the API and refresh the calendar's view with these new events
     */
    const refetchCalendar = function () {
      uiCalendarConfig.calendars.calendar.fullCalendar('refetchEvents');
      setTimeout(() => {
        uiCalendarConfig.calendars.calendar.fullCalendar('rerenderEvents');
      }, 200);
    };

    // !!! MUST BE CALLED AT THE END of the controller
    return initialize();
  }
]);
